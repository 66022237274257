// import client from '@/apis/http-client'
// import Vue from 'vue'

// ========================================================================
// TODO: Remove mocking data and helper
// function when fully implemented.
import client from '@/apis/http-client'
import moment from 'moment'
import Vue from 'vue'
let pathUrl = `/por/api`
// const pathOrgUrl = `por/api/organization?page_size=1000`
const pathOrgUrl = `sso/rbac/organization/permission`
import {convertFileToBase64} from '../helper/helper'
import qs from 'qs'
import {doctorCategoryEnum} from '@/constants/doctor'
import {metaEnum} from '@/constants/package'

// End: TODO
// ========================================================================

// Initial state.
const defaultState = () => {
  return {
    // Filters
    filterStatus: '',
    filterHospital: '',
    filterDepartment: null,
    filterSpecialty: null,
    orgList: {
      all: [],
      selected: []
    },
    languageList: [],
    departmentList: [],
    filterCategory: '',
    textSearch: '',
    deleteId: 0,
    isDelete: false,
    // List of doctor
    category: [{text: 'All', value: ''}],
    sortableList: [],
    sortableTotal: 0,
    isLoading: false,
    isLoadingBranch: false,
    doctorList: [],
    updateIdList: [],
    isPreview: false,
    currentPage: 1,
    pageSize: 25,
    total: 0,
    sortBy: 'update_date',
    sortDesc: 'DESC',
    orgObject: [],
    specialtyListTh: [],
    specialtyListEn: [],
    prefixObject: [],
    department_id: '',
    org_codename: '',
    category_doctor: '',
    license_no: '',
    isDuplicateLicenseNo: false,
    // doctor object
    doctorObject: {
      profile: '',
      id: '',
      org_codename: '',
      category: 1,
      contents: [
        {
          code: 'th',
          name: null,
          doctor_id: '',
          prefix_name: ''
        },
        {
          code: 'en',
          name: null,
          doctor_id: '',
          prefix_name: ''
        }
      ],
      department: [],
      specialty: [],
      department_id: '',
      specialty_id: '',
      status: 0,
      prefix_id: '',
      create_by_user: null,
      update_by_user: null,
      alias: '',
      profile_alt: '',
      tags: [],
      specialty_en_tags: [],
      specialty_th_tags: [],
      department_ids: [],
      language_ids: [],
      meta: {
        SEO: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ],
        FACEBOOK: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ],
        X: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ]
      }
    },
    editOrderObject: {
      profile: '',
      id: '',
      org_codename: '',
      category: 1,
      contents: [
        {
          code: 'th',
          name: null,
          doctor_id: '',
          prefix_name: ''
        },
        {
          code: 'en',
          name: null,
          doctor_id: '',
          prefix_name: ''
        }
      ],
      department: [],
      specialty: [],
      department_id: '',
      specialty_id: '',
      status: 0,
      prefix_id: '',
      create_by_user: null,
      update_by_user: null
    },
    isEditOrder: false,
    isEditMode: false,
    isHeaderImageError: false,
    headerImageErrorMessage: '',
    metaImageErrorMessage: {
      [metaEnum.SEO]: {
        en: '',
        th: ''
      },
      [metaEnum.FACEBOOK]: {
        en: '',
        th: ''
      },
      [metaEnum.X]: {
        en: '',
        th: ''
      }
    },
    isMetaImageError: {
      [metaEnum.SEO]: {
        en: false,
        th: false
      },
      [metaEnum.FACEBOOK]: {
        en: false,
        th: false
      },
      [metaEnum.X]: {
        en: false,
        th: false
      }
    },
    duplicateContent: {
      doctor: false,
      [metaEnum.SEO]: false,
      [metaEnum.FACEBOOK]: false,
      [metaEnum.X]: false
    },
    pageLink: '',
    pageLinkEn: ''
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  async setAlias({commit}, alias) {
    const {filterHospital} = state
    const params = {
      alias: alias,
      db: 'doctor',
      org_codename: filterHospital,
      id: state.doctorObject.id
    }
    try {
      let data = await client.secureHttpClient.post(
        `${pathUrl}/alias/checked`,
        {
          ...params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
      )
      if (data.data.success) {
        commit('SET_ALIAS', data.data.data)

        let pageLink = `${data.data.website}/doctor/${data.data.data}`
        let pageLinkEn = `${data.data.website}en/doctor/${data.data.data}`

        if (data.data.website.endsWith('/')) {
          pageLink = `${data.data.website}doctor/${data.data.data}`

          pageLinkEn = `${data.data.website}en/doctor/${data.data.data}`
        }

        commit('SET_PAGELINK', pageLink)
        commit('SET_PAGELINK_EN', pageLinkEn)
      }
    } catch (e) {
      Vue.$log.error(e)
    }
  },
  setHeaderImage({commit}, file) {
    commit('SET_HEADER_IMAGE', file)
  },
  setHeaderImageError({commit}, data) {
    commit('SET_HEADER_IMAGE_ERROR', data)
  },
  async setOrgInfo({commit}, isViewOnly) {
    let mode = ''
    if (isViewOnly) {
      mode = 'View'
    } else if (state.isEditMode) {
      mode = 'Edit'
    } else {
      mode = 'Add'
    }

    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${mode}&category=Doctor`
    )
    commit('SET_ORG_DETAIL', data.data)
  },
  async setListOrgInfo({commit}) {
    commit('SET_IS_LOADING_BRANCH', true)
    try {
      let data = await client.secureHttpClient.get(
        `${pathOrgUrl}?permission=View&category=Doctor`
      )
      commit('SET_ORG_LIST', data.data)
      commit('SET_ORG_FILTER', data.data)
      commit('SET_IS_LOADING_BRANCH', false)
    } catch (e) {
      commit('SET_IS_LOADING_BRANCH', false)
    }
  },
  async fetchLanguageList({commit}) {
    try {
      let data = await client.secureHttpClient.get(`${pathUrl}/languages`)
      commit('SET_LANGUAGE_LIST', data.data.data)
    } catch (e) {
      Vue.$log.error(e)
    }
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  resetSearchAndFilter({commit}) {
    commit('RESET_SEARCH_AND_FILTER')
  },
  resetDepartmentList({commit}) {
    commit('SET_DEPARTMENT_LIST', defaultState().departmentList)
  },
  async setDepartment({commit}, isDetail) {
    const {filterHospital} = state
    let data = await client.secureHttpClient.get(
      `${pathUrl}/doctor/departments?org_codename=${filterHospital}`
    )
    if (isDetail) {
      commit('SET_DEPARTMENT_DETAIL', data.data)
    } else {
      commit('SET_DEPARTMENT_LIST', data.data)
    }
  },
  async fetchDepartment({commit}, orgs) {
    try {
      const params = {
        org_codename: orgs
      }
      let data = await client.secureHttpClient.get(
        `${pathUrl}/departmentlist?lang=th`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
      )
      commit('SET_DEPARTMENT_LIST', data.data.data)
    } catch (e) {
      Vue.$log.error(e)
    }
  },
  async setPrefix({commit}) {
    let data = await client.secureHttpClient.get(`${pathUrl}/prefix`)
    commit('SET_PREFIX_LIST', data.data)
  },
  setImageDoctor({commit}, file) {
    commit('SET_IMAGE_DOCTOR', file)
  },
  setImageThai({commit}, file) {
    commit('SET_IMAGE_THAI', file)
  },
  setImageEng({commit}, file) {
    commit('SET_IMAGE_ENG', file)
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  setDeleteId({commit}, id) {
    commit('SET_DELETE_ID', id)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterStatus({commit}, status) {
    let selected = ''
    if (status.length === 1) {
      selected = status[0]
    }
    commit('SET_FILTER_STATUS', selected)
  },
  setFilterCategory({commit}, status) {
    let selected = ''
    if (status.length === 1) {
      selected = status[0] === doctorCategoryEnum.FULL_TIME.toString() ? 1 : 0
    }
    commit('SET_FILTER_CATEGORY', selected)
  },
  setFilterHospital({commit}, status) {
    commit('SET_FILTER_HOSPITAL', status)
  },
  setFilterDepartment({commit}, status) {
    commit('SET_FILTER_DEPARTMENT', status)
  },
  setFilterSpecialty({commit}, status) {
    commit('SET_FILTER_SPECIALTY', status)
  },
  setShowPreview({commit}, status) {
    // isPreview:false,
    commit('SET_SHOW_PREVIEW', status)
  },
  setShowEditOrder({commit}, status) {
    commit('SET_SHOW_EDIT_ORDER', status)
  },
  setTextSearch({commit}, text) {
    if (text == state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setSelectedId({commit}, ids) {
    commit('SET_UPDATE_ID', ids)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  setIsDuplicateLicenseNo({commit}, status) {
    commit('SET_IS_DUPLICATE_LICENSE_NO', status)
  },
  async setDoctorId({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/doctor/${id}`)
    if (data) {
      commit('SET_DOCTOR', data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  /// EDIT call
  async update({commit, dispatch}, status) {
    let ids = state.updateIdList.map(id => {
      return id.id
    })
    let body = {ids: ids, status: status}
    try {
      await client.secureHttpClient.patch(`${pathUrl}/doctor/bulk-update`, body)
      dispatch('fetch')
      commit('SET_UPDATE_ID', [])
      return true
    } catch (e) {
      Vue.$log.error(e)
      return false
    }
  },
  async updateAllStatus({commit}, {ids, status}) {
    try {
      ids = ids.map(id => {
        return id.id
      })
      let body = {
        ids: ids,
        status: status
      }
      commit('SET_LOAD', true)
      await client.secureHttpClient.patch(`${pathUrl}/doctor/bulk-update`, body)
      commit('SET_LOAD', false)
      return true
    } catch (e) {
      commit('SET_LOAD', false)
      return false
    }
  },
  async upload(_, payload) {
    try {
      let data = await client.secureHttpClient.post(
        `${pathUrl}/uploadImage`,
        payload
      )
      return data.data.url
    } catch (e) {
      Vue.$log.error(e)
      return ''
    }
  },
  async deletePkg({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(
        `${pathUrl}/doctor/${state.deleteId}`
      )
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_ID', 0)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_POPUP_DELETE', false)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  // API calls
  async reorderList({commit}, clonedList) {
    commit('SET_SORTABLE_LIST', clonedList)
    const temp = [...clonedList]
    let resultsReorder = temp.reverse().map((object, index) => {
      return {id: object.id, order: index}
    })
    await client.secureHttpClient.patch(`${pathUrl}/doctors/reorder`, {
      doctors: resultsReorder
    })
  },
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    // const   {page, search, limit, status, category, sortBy, sortDesc} = state
    const {
      currentPage,
      pageSize,
      textSearch,
      filterStatus,
      sortBy,
      sortDesc,
      filterCategory,
      filterDepartment,
      filterSpecialty,
      orgList
    } = state
    try {
      const params = {
        page: currentPage,
        limit: pageSize,
        q: textSearch,
        orderBy: sortBy,
        sort: sortDesc,
        ...(filterStatus !== '' && {status: filterStatus}),
        ...(filterCategory !== '' && {category: filterCategory}),
        ...(filterDepartment !== null && {department_id: filterDepartment}),
        ...(filterSpecialty !== null && {specialty_id: filterSpecialty}),
        ...(orgList.selected.length > 0 && {
          org_codename: orgList.selected.map(o => o.codename)
        })
      }

      let data = await client.secureHttpClient.get(`${pathUrl}/doctors`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL', data.data.total)
      commit('SET_DOCTOR_LIST', data.data.results)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async fetchSortableList({commit}) {
    const {orgList} = state
    commit('SET_IS_LOADING', true)
    try {
      const params = {
        listType: 'sorting',
        org_codename: orgList.selected.map(o => o.codename)
      }
      let data = await client.secureHttpClient.get(`${pathUrl}/doctors`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_SORTABLE_TOTAL', data.data.results.length)
      commit('SET_SORTABLE_LIST', data.data.results)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async setEditOrder({commit}, item) {
    const indexNo = state.sortableList.findIndex(x => x.id === item.id)
    commit('SET_EDIT_ORDER_OBJECT', {
      ...item,
      indexNo,
      total: state.sortableList.length
    })
    commit('SET_SHOW_EDIT_ORDER', true)
  },
  setStyleImage({commit}) {
    commit('SET_STYLE_CONTENT')
  },

  async updatePkg({commit}, payload) {
    const {pkg, id} = payload
    commit('SET_IS_LOADING', true)
    try {
      if (pkg.status === 2) pkg.public_date = moment(pkg.public_date)
      let data = await client.secureHttpClient.put(
        `${pathUrl}/doctor/${id}`,
        pkg
      )
      commit('SET_IS_LOADING', false)
      return data.data.success
    } catch (e) {
      Vue.$log.error(e)
      if (
        e.response.data.message ===
        '"License No" already exists in organization'
      ) {
        state.isDuplicateLicenseNo = true
      }
      commit('SET_IS_LOADING', false)
    }
  },
  async create({commit}, pkg) {
    commit('SET_IS_LOADING', true)
    try {
      await client.secureHttpClient.post(`${pathUrl}/doctor/`, pkg)
      commit('RESET_STATE')
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      Vue.$log.error(e.response.data.message)

      if (
        e.response.data.message ===
        '"License No" already exists in organization'
      ) {
        state.isDuplicateLicenseNo = true
      }
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async fetchDoctor(
    {commit},
    {page, search, limit, status, category, sortBy, sortDesc}
  ) {
    try {
      commit('SET_LOAD', true)
      let queryString = `?page=${page}&&limit=${limit}&&q=${search}&&status=${status}&&category=${
        category[0].text
      }&orderBy=${sortBy[0] ? sortBy[0] : 'update_date'}&sort=${
        sortDesc[0] ? 'DESC' : 'ASC'
      }`
      let data = await client.secureHttpClient.get(
        `${pathUrl}/doctor${queryString}`
      )
      if (data.data) {
        commit('SET_DATA', data)
      }
      commit('SET_LOAD', false)
    } catch (e) {
      commit('SET_LOAD', false)
    }
  },
  async fetchCategory({commit}) {
    //TODO: debug data input
    // try {
    //   let category = await client.secureHttpClient.get(`${pathUrl}/category`)
    //   commit('SET_CATEGORY', category)
    // } catch (e) {
    commit('SET_CATEGORY', {
      data: [
        {id: 1, title: 'Full Time'},
        {id: 0, title: 'Part Time'}
      ]
    })
    // }
  },
  //   async fetchDepartment({commit}) {
  //     //TODO: debug data input
  //     try {
  //       let department = await client.secureHttpClient.get(`${pathUrl}/category`)
  //       commit('SET_DEPARTMENT', department)
  //     } catch (e) {
  //       commit('SET_DEPARTMENT', {data: [{"id": 0,"title":"Part Time"},{"id": 1,"title":"Full Time"}]})
  //     }
  //   }
  async fetchSpecialty({commit}) {
    try {
      let specialty = await client.secureHttpClient.get(
        `${pathUrl}/doctor/specialties?lang=th`
      )
      commit('SET_SPECIALTY_LIST_TH', specialty.data.data)
    } catch (e) {
      Vue.$log.error(e)
    }

    try {
      let specialty = await client.secureHttpClient.get(
        `${pathUrl}/doctor/specialties?lang=en`
      )
      commit('SET_SPECIALTY_LIST_EN', specialty.data.data)
    } catch (e) {
      Vue.$log.error(e)
    }
  },
  setMetaImageError({commit}, data) {
    commit('SET_META_IMAGE_ERROR', data)
  },
  setMetaImage({commit}, data) {
    commit('SET_META_IMAGE', data)
  }
}

// Mutations
const mutations = {
  SET_META_IMAGE_ERROR(state, {lang, type, isError, message}) {
    state.isMetaImageError[type][lang] = isError
    state.metaImageErrorMessage[type][lang] = message
  },
  SET_META_IMAGE(state, {lang, type, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.doctorObject.meta[type][0].image = base64
        }
        if (lang === 'en') {
          state.doctorObject.meta[type][1].image = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_ORG_LIST(state, org) {
    let results = org.data.map(object => {
      return {text: object.name, value: object.codename}
    })
    results = insert(results, 0, {text: 'All', value: ''})
    state.orgObject = results
  },
  SET_ORG_FILTER(state, org) {
    const selected = []

    if (org.data.length === 1) {
      org.data.forEach(d =>
        selected.push({
          codename: d.codename,
          selected: true
        })
      )
    }
    state.orgList.selected = selected
    state.orgList.all = org.data
  },
  SET_LANGUAGE_LIST(state, langs) {
    let results = langs.map(object => {
      return {text: object.name, value: object.id}
    })
    state.languageList = results
  },
  SET_DEPARTMENT_LIST(state, departments) {
    state.departmentList = departments
  },
  SET_PREFIX_LIST(state, prefix) {
    let results = prefix.data.map(object => {
      return {text: object.value_th, value: object.id, text_en: object.value_en}
    })
    state.prefixObject = results
  },
  SET_ORG_DETAIL(state, org) {
    let results = org.data.map(object => {
      return {text: object.name, value: object.codename}
    })
    state.orgObject = results
  },
  SET_DEPARTMENT_DETAIL(state, dept) {
    let results = dept.data.map(object => {
      return {text: object.name, value: object.id}
    })
    state.departmentList = results
  },
  RESET_STATE(state) {
    state.doctorObject = defaultState().doctorObject
    state.doctorObject.status = 0
    state.pageLink = defaultState().pageLink
    state.pageLinkEn = defaultState().pageLinkEn
    state.isHeaderImageError = defaultState().isHeaderImageError
    state.headerImageErrorMessage = defaultState().headerImageErrorMessage
  },
  RESET_LIST_STATE(state) {
    state.departmentList = defaultState().departmentList
    state.doctorList = defaultState().doctorList

    state.filterStatus = defaultState().filterStatus
    state.filterCategory = defaultState().filterCategory
    state.filterDepartment = defaultState().filterDepartment
    state.filterSpecialty = defaultState().filterSpecialty
    state.orgList = defaultState().orgList

    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.textSearch = defaultState().textSearch
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
  },
  RESET_SEARCH_AND_FILTER(state) {
    state.departmentList = defaultState().departmentList
    state.doctorList = defaultState().doctorList

    state.filterStatus = defaultState().filterStatus
    state.filterCategory = defaultState().filterCategory
    state.filterDepartment = defaultState().filterDepartment
    state.filterSpecialty = defaultState().filterSpecialty

    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.textSearch = defaultState().textSearch
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
  },
  SET_STYLE_CONTENT(state) {
    state.doctorObject.contents[0].description = state.doctorObject.contents[0].description
      .toString()
      .split('<img')
      .join('<img width="100%" height="auto"')
    state.doctorObject.contents[1].description = state.doctorObject.contents[1].description
      .toString()
      .split('<img')
      .join('<img width="100%" height="auto"')
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_IS_LOADING_BRANCH(state, isLoading) {
    state.isLoadingBranch = isLoading
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },

  SET_CATEGORY(state, category) {
    let results = category.data.map(object => {
      return {text: object.title, value: object.id}
    })
    results = insert(results, 0, {text: 'All', value: ''})
    state.category = results
  },
  SET_DEPARTMENT(state, category) {
    let results = category.data.map(object => {
      return {text: object.title, value: object.id}
    })
    state.departmentList = results
  },
  SET_SPECIALTY_LIST_TH(state, specialty) {
    const specialties = specialty.map(object => {
      return {text: object.content.title, value: object.id}
    })
    state.specialtyListTh = specialties
  },
  SET_SPECIALTY_LIST_EN(state, specialty) {
    const specialties = specialty.map(object => {
      return {text: object.content.title, value: object.id}
    })
    state.specialtyListEn = specialties
  },
  SET_FILTER_STATUS(state, status) {
    state.filterStatus = status
  },
  SET_FILTER_HOSPITAL(state, status) {
    state.filterHospital = status
  },
  SET_FILTER_DEPARTMENT(state, status) {
    state.filterDepartment = status
  },
  SET_FILTER_SPECIALTY(state, status) {
    state.filterSpecialty = status
  },
  SET_FILTER_CATEGORY(state, status) {
    state.filterCategory = status
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_SORTABLE_TOTAL(state, total) {
    state.sortableTotal = total
  },
  SET_IS_DUPLICATE_LICENSE_NO(state, status) {
    state.isDuplicateLicenseNo = status
  },
  SET_DOCTOR_LIST(state, doctor) {
    state.doctorList = doctor
  },
  SET_SORTABLE_LIST(state, list) {
    state.sortableList = list
  },
  SET_DOCTOR(state, pkg) {
    state.doctorObject = pkg.data
    const prefixName =
      pkg.data.contents[0].code === 'th'
        ? pkg.data.contents[0].prefix_name
        : pkg.data.contents[1].prefix_name
    const prefix = state.prefixObject.find(p => p.text === prefixName)
    if (prefix !== undefined && 'value' in prefix) {
      state.doctorObject.prefix_id = prefix.value
    } else {
      state.doctorObject.prefix_id = ''
    }
  },
  SET_EDIT_ORDER_OBJECT(state, doctor) {
    state.editOrderObject = doctor
  },
  SET_SHOW_EDIT_ORDER(state, status) {
    state.isEditOrder = status
  },
  SET_DOCTOR_PREVIEW(state, pkg) {
    state.doctorObject = pkg.data
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_UPDATE_ID(state, ids) {
    state.updateIdList = ids
  },
  SET_IMAGE_DOCTOR(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.doctorObject.profile = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_ID(state, id) {
    state.deleteId = id
  },
  SET_SHOW_PREVIEW(state, status) {
    state.isPreview = status
  },
  SET_HEADER_IMAGE(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.doctorObject.profile = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_HEADER_IMAGE_ERROR(state, {isError, message}) {
    state.isHeaderImageError = isError
    state.headerImageErrorMessage = message
  },
  SET_ALIAS(state, alias) {
    state.doctorObject.alias = alias
  },
  SET_PAGELINK(state, pageLink) {
    state.pageLink = pageLink
  },
  SET_PAGELINK_EN(state, pageLinkEn) {
    state.pageLinkEn = pageLinkEn
  }
}
const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index)
]
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
